@import 'styles/palettes/itorum_palette';
@import "styles/palettes/develit_palette";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$itorum-frontend-primary: mat-palette($itorum-green-palette);
$itorum-frontend-accent: mat-palette($mat-yellow, A200, A100, A400);

// The warn palette is optional (defaults to red).
$itorum-frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$itorum-frontend-theme: mat-light-theme((
  color: (
    primary: $itorum-frontend-primary,
    accent: $itorum-frontend-accent,
    warn: $itorum-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($itorum-frontend-theme);

.alter-theme {
  $alter-primary: mat-palette($develit-blue-palette, 50, 50, 50);
  $alter-accent: mat-palette($mat-yellow, 400);
  $alter-theme: mat-light-theme((
    color: (
      primary: $alter-primary,
      accent: $alter-accent
    )
  ));
  @include angular-material-theme($alter-theme);

  .mat-input-element {
    color: white;
  }

  .mat-form-field-label {
    color: white;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white;
  }

  .mat-tab-label-content {
    color: white;
  }
}
