//@use '@angular/material/theming' as mat;
@import '~@angular/material/theming';
//@import '../var';

$develit-blue-palette: (
  50: #ebfeff,
  100: #cdfbff,
  200: #9ae8ef,
  300: #73d4e5,
  400: #60cfef,
  500: #50caef,
  600: #35d0e5,
  700: #2fd3c0,
  800: #28c6c1,
  900: #1cb2b7,
  A100: #80fff4,
  A200: #52f9ff,
  A400: #17ffe4,
  A700: #00c3d5,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);
